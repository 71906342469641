<template>
<div>
  
</div>
  <router-view />
</template>

<script>
import DashboardVue from '../components/Dashboard.vue';

export default {
  name: "Blank",
  components:DashboardVue
};
</script>
